import { catApi, catMain } from "@/util/logging";

import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { api } from "@/util/api";

export class VerlagListeModel extends BaseType {
  @Expose() uid = "";
  @Expose() aurl = "";
  @Expose() vurl = "";
  @Expose() titel = "";

  // === === === === === === === === === === === === === ===

  public get anschrift_id(): undefined | number {
    catMain.info(
      `getting Anschrift ID from ${this.aurl} --> ${BaseType.idFromURL(
        this.aurl
      )}`
    );
    return BaseType.idFromURL(this.aurl);
  }

  // === === === === === === === === === === === === === ===

  static getList = async (filter?: string): Promise<VerlagListeModel[]> => {
    catApi.info(`querying with ${filter}`);

    try {
      const options = { params: {} };
      if (filter !== undefined) {
        options.params = {
          filter: filter,
        };
      }
      const path = `v1/verlage/`;
      const response = await api.get<VerlagListeModel[]>(path, options);
      catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

      const ret = BaseType.convertArray(VerlagListeModel, response.data);

      if (!Array.isArray(ret)) {
        catApi.error("Should have gotten a List, got nothing!", null);
        throw new Error("VerlagListeModel no list");
      }

      return ret;
      //
    } catch (e) {
      //
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      return Promise.reject(err);
    }
  };
}
