import { api } from "@/util/api";
import { catApi, catMain } from "@/util/logging";
import { Expose, Type } from "class-transformer";
import { BaseType } from "../util/BaseType";
import { VerlagListeModel } from "../VerlagListeModel";
import { Zeile } from "./Zeile";

export class Kopf extends BaseType {
  //
  @Expose() uid = "";
  //
  @Expose() rchg_id: number | null = null;
  @Expose() nummer: number | null = null;
  @Expose() datum: string | null = null;
  @Expose() anschrift_id: number | null = null;

  @Expose()
  @Type(() => Zeile)
  zeilen: Zeile[] = [];

  @Expose() public get aurl(): string | undefined {
    return this._anschrift?.aurl;
  }

  private _anschrift = null as null | VerlagListeModel;
  public get anschrift(): VerlagListeModel | undefined {
    if (this._anschrift === null) return undefined;
    return this._anschrift;
  }
  public set anschrift(a: VerlagListeModel | undefined) {
    catMain.info(`Setting ... ${a}`);
    if (a === undefined) {
      this._anschrift = null;
      this.anschrift_id = null;
      return;
    }

    if (a.anschrift_id === undefined) this.anschrift_id = null;
    else this.anschrift_id = a.anschrift_id;

    if (this.anschrift_id !== null) this._anschrift = a;
  }

  public get anschriftTitel(): string {
    // catMain.debug(`Getting Titel ... ${this._anschrift}`);
    if (this._anschrift !== null) return this._anschrift.titel;
    return "-/-";
  }

  // === === === === === === === === === === === === === ===

  static make(ans: VerlagListeModel): Kopf {
    const ret = new Kopf();

    ret.uid = "<-temp->" + ans.uid;
    ret.anschrift = ans;

    return ret;
  }

  // === === === === === === === === === === === === === ===

  static getList = async (filter?: string): Promise<VerlagListeModel[]> => {
    catApi.info(`querying with ${filter}`);

    try {
      const options = { params: {} };
      if (filter !== undefined) {
        options.params = {
          filter: filter,
        };
      }
      const path = `v1/verlage/`;
      const response = await api.get<VerlagListeModel[]>(path, options);
      catApi.info(`response: /${path} -> ${JSON.stringify(response)}`);

      const ret = BaseType.convertArray(VerlagListeModel, response.data);

      if (!Array.isArray(ret)) {
        catApi.error("Should have gotten a List, got nothing!", null);
        throw new Error("VerlagListeModel no list");
      }

      return ret;
      //
    } catch (e) {
      //
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      return Promise.reject(err);
    }
  };
}
