


























import { Component, Vue } from "vue-property-decorator";
import { catVue } from "@/util/logging";

import { Kopf } from "@/models/Rechnungen/Kopf";
import { VerlagListeModel } from "@/models/VerlagListeModel";
import store from "@/store";
import VerlagsRechnungModule from "@/store/modules/vr";
import { getModule } from "vuex-module-decorators";

// eslint-disable-next-line

const vrState = getModule(VerlagsRechnungModule, store);

@Component({
  components: {
    PresseRechnungKopf: () =>
      import(
        /* webpackChunkName: "presse-rechnung-kopf" */ "@/components/rechnungen/PresseRechnungKopf.vue"
      ),
    ListeUnfertigerRechnungen: () =>
      import(
        /* webpackChunkName: "liste-unfertige-rechnungen" */ "@/components/rechnungen/ListeUnfertigerRechnungen.vue"
      ),
  },
})
export default class PresseRechnungen extends Vue {
  //
  vrState = vrState;

  onSelect(event: VerlagListeModel | null): void {
    console.log(event);
    if (event) {
      const k = Kopf.make(event);
      catVue.info(`${this.$options.name}:: Mutating ... ${this.value}`);
      this.vrState.updateRechnung(k);
      catVue.info(`${this.$options.name}:: Mutated ... ${this.value}`);
    }
    return;
  }

  get value(): string | undefined {
    try {
      if (!("aktuelleRechnung" in this.vrState)) {
        catVue.info(`no aktuelleRechnung??`);
        return undefined;
      }
      //  console.log(this.vrState);
      //  console.log(this.vrState.rechnungen);
      //  console.log(this.vrState.aktuelleRechnung);
      //  catVue.info(`value() accessor ... ${MyJSON.out(this.vrState)}`);
      return this.vrState.aktuelleRechnung?.anschriftTitel;
    } catch (e) {
      catVue.info(`exception aktuelleRechnung??`);
      return undefined;
    }
  }

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
    if (this.vrState?.aktuelleRechnung === undefined) {
      catVue.info(`no aktuelleRechnung??`);
    }

    catVue.info(
      `${this.$options.name}:: aktuell -> ${this.vrState?.aktuelleRechnung}`
    );
  }
}
