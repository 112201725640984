import { BaseType } from "../util/BaseType";
import { Expose } from "class-transformer";
import { VerlagListeModel } from "../VerlagListeModel";
import { api } from "@/util/api";
import { catApi } from "@/util/logging";

export class Zeile extends BaseType {
  //
  @Expose() uid = "";
  //
  @Expose() rchg_id?: number;
  @Expose() nummer?: number;
  @Expose() datum?: string;
  @Expose() anschrift_id?: number;

  @Expose() public get aurl(): string | undefined {
    return this._anschrift?.aurl;
  }

  private _anschrift = null as null | VerlagListeModel;
  public set anschrift(a: VerlagListeModel) {
    this.anschrift_id = a.anschrift_id;
    if (this.anschrift_id !== undefined) this._anschrift = a;
  }

  // === === === === === === === === === === === === === ===

  static getList = async (filter?: string): Promise<VerlagListeModel[]> => {
    catApi.info(`querying with ${filter}`);

    try {
      const options = { params: {} };
      if (filter !== undefined) {
        options.params = {
          filter: filter,
        };
      }
      const path = `v1/verlage/`;
      const response = await api.get<VerlagListeModel[]>(path, options);
      catApi.info(`response: /${path} -> ${JSON.stringify(response)}`);

      const ret = BaseType.convertArray(VerlagListeModel, response.data);

      if (!Array.isArray(ret)) {
        catApi.error("Should have gotten a List, got nothing!", null);
        throw new Error("VerlagListeModel no list");
      }

      return ret;
      //
    } catch (e) {
      //
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      return Promise.reject(err);
    }
  };
}
